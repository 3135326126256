.primary {
    color: var(--color-primary) !important;
}

.danger {
    color: var(--color-refuse);
}

.success {
    color: var(--color-success);
}