/* ---------------- ROOT VARIABLES ------------- */


:root {
    --color-background: #fff;
    --color-primary: #1C2E82;
    --color-bleu-ligth: #3150fd;
    --color-primary-light: rgba(28, 46, 130, .3);
    --color-light: rgba(132, 139, 200, .5);
    --color-white: #fff;
    --color-black-light: #333;
    --color-orange: #F8941E;
    --border-raduis: 0.8rem;
    --box-shadow: 0 .1rem .3rem var(--color-light);
    --box-shadow-strong: 0 0 10px 1px var(--color-light);
    --transition: all 300ms ease;
}