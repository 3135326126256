@import url("./varriable.css");
@import url("./color.css");
@import url("./heading.css");
@import url("./margin.css");
@import url("./grid.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

/* ---------------- GENERAL ------------- */

*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 0.85rem;
}

body {
    height: auto;
    font-family: poppins, sans-serif;
    font-size: 0.88rem;
    background-color: var(--color-background);
    color: var(--color-black);
}

input,
textarea {
    font-family: "Poppins", sans-serif;
}

img {
    display: block;
    width: 100%;
}

a {
    color: var(--color-black);
}


.container {
    position: relative;
    width: 100%;
    min-height: 87vh;
    background-color: var(--color-white);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.center {
    display: flex;
    justify-content: center;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.container-fluid {
    padding: 0 12rem;
}



/* For mobile phones: */
@media screen and (max-width: 768px) {
    .grid-4 {
        grid-template-columns: 1fr;
    }
}