h1 {
    font-size: 1.8rem;
    font-weight: 800;
}

h2 {
    font-size: 1.4rem;
    font-weight: 600;
}

h3 {
    font-size: 0.87rem;
    font-weight: normal;
}

/* For mobile phones: */
@media screen and (max-width: 768px) {
    
    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1rem;
    }
}