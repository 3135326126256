.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-self: center;
  justify-self: center;
}

.custom-grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1.7fr 0fr;
  gap: 1rem;
}

.custom-grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 0fr;
  gap: 1rem;
}

/* For mobile phones: */
@media screen and (max-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }

  .custom-grid-4 {
    grid-template-columns: 1fr;
  }

  .sm-grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}